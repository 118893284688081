<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <validation-observer ref="simpleRules">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50"
          @click="$router.go(-1)"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
        </b-button>
        <button
          class="btn btn-gradient-primary mb-1"
          @click="Submit()"
        >
          {{ $t('saveData') }}
        </button>

        <b-row>
          <b-col lg="6">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('parcelInfo') }}
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">

                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('parcelCode') }} : <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="parcel code"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="items.postnumber"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isDisabled"
                          @input="checkInput"
                        />
                        <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('quantity') }} : <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="parcel code"
                        rules="required"
                      >
                        <b-form-input
                          id="h-parcel-code"
                          v-model="items.product_amount"
                          :disabled="isDisabled"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                          class="w-25"
                        />
                        <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-purchase-order"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('key-22') }} :
                      </template>

                      <b-form-input
                        id="h-purchase-order"
                        v-model="items.tag_id"
                        :disabled="isDisabled"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-keyword"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('mnemonic') }} :
                      </template>

                      <b-form-input
                        id="h-keyword"
                        v-model="items.keyword"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-customerCode"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('transport') }} :
                      </template>

                      <div class="demo-inline-spacing mt-n1">
                        <b-form-radio
                          v-model="items.transportType"
                          name="some-radio9"
                          :value="1"
                          class="custom-control-primary"
                          :disabled="isDisabled"
                        >
                          {{ $t('truck') }}
                        </b-form-radio>

                        <b-form-radio
                          v-model="items.transportType"
                          name="some-radio9"
                          :value="2"
                          :disabled="isDisabled"
                          class="custom-control-primary"
                        >
                          {{ $t('ship') }}
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('productType') }} :
                      </template>
                      <span v-if="items.product_type">
                        {{ this.$i18n.locale === 'th' ? items.product_type.pro_name: this.$i18n.locale === 'en' ? items.product_type.pro_name_en: this.$i18n.locale === 'cn'
                          ? items.product_type.pro_name_cn: ''
                        }}
                      </span>

                      <span v-else>
                        -
                      </span>

                      <!-- {{ items.product_type ? items.product_type.pro_name: '-' }} -->
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-packbox"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('woodPacking') }} :
                      </template>

                      <b-form-checkbox
                        v-model="items.packbox"
                        name="check-button"
                        class="mt-50"
                        switch
                        inline
                        :disabled="isDisabled"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-checkProduct"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('checkOrder') }} :
                      </template>

                      <b-form-checkbox
                        v-model="items.checkproduct"
                        name="check-button"
                        class="mt-50"
                        switch
                        inline
                        :disabled="isDisabled"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-rak"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('remarks') }} :
                      </template>
                      {{ items.remark ? items.remark:'-' }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-remark"
                      label-cols="4"
                      label-cols-lg="3"
                    >
                      <template v-slot:label>
                        {{ $t('key-117') }} :
                      </template>
                      <div>
                        <span
                          v-for="imagePr in items.path_img"
                          :key="imagePr"
                          class="od-pc-offer-price-common "
                        >
                          <img
                            :src="GetImg('postnumber_img',imagePr)"
                            accept=".jpg, .png, .jpeg"
                            alt="รูปภาพ"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                            @click="showPicture('postnumber_img',items.path_img)"
                          >
                        </span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('moneyList') }}
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-checkProduct"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ checkcharge_per(items) }} :
                      </template>

                      {{ items.charge_per_save ? Commas(items.charge_per_save): '-' }} {{ $t('baht') }}

                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-checkProduct"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('checkOrder') }} :
                      </template>
                      {{ Commas(items.product_inspection_fee) }} {{ $t('baht') }}

                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-name"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('repackage') }} :
                      </template>
                      {{ Commas(items.re_wrap_fee) }} {{ $t('baht') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-trans"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('key-114') }} :
                      </template>
                      {{ Commas(items.shipping_fee) }} {{ $t('baht') }}
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-priceBox"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('cratePricing') }} :
                      </template>
                      {{ Commas(items.packaging_fee) }} {{ $t('baht') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-other"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('other') }} :
                      </template>
                      {{ Commas(items.other) }}  {{ $t('baht') }}
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>

          <b-col md="6">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('size') }}
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-name"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('weight') }} :
                      </template>
                      {{ Commas(items.weight) }}  {{ $t('kilo') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-name"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('widthCm') }} :
                      </template>
                      {{ Commas(items.width) }}  {{ $t('cm') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-name"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('heightCm') }} :
                      </template>
                      {{ Commas(items.height) }} {{ $t('cm') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-product-name"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('lengthCm') }} :
                      </template>
                      {{ Commas(items.long) }} {{ $t('cm') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel1"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('key-100') }} :
                      </template>
                      {{ Commas(items.weight* items.product_amount) }}  {{ $t('kilo') }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-kilo"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('key-101') }} :
                      </template>
                      {{ CommasCue(((items.width*items.height*items.long)/1000000)* items.product_amount) }}  {{ $t('cubic') }}
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('date') }}
                </h3>
              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="example-received"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('arrivedChinaWarehouse') }} :
                      </template>
                      {{ items.come_chinesewarehouse? items.come_chinesewarehouse:'-' }}
                    <!-- {{ moment(receivedDate).format('DD/MM/YYYY') }} -->
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="example-export"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('leftChinaWarehouse') }} :
                      </template>
                      {{ items.out_chinesewarehouse ? items.out_chinesewarehouse: '-' }}
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="example-arrived-at"
                      label-cols="4"
                      label-cols-lg="3"
                      class="d-flex align-items-center"
                    >
                      <template v-slot:label>
                        {{ $t('arrivedThaiWarehouse') }} :
                      </template>
                      {{ items.come_thaiwarehouse ? items.come_thaiwarehouse: '-' }}
                    </b-form-group>
                  </b-col>

                  <!-- <b-col md="12">
                    <b-form-group
                      label-for="example-arrived-at"
                      label-cols="4"
label-cols-lg="3"
                    >
                      <template v-slot:label>
                        ถึงไทย :
                      </template>
                      {{ items.out_thaiwarehouse? items.out_thaiwarehouse: '-' }}
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </div>
          </b-col>

        </b-row>
      </validation-observer>
    </div>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    VueImageLightboxCarousel,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,
      checkProduct: false,
      customerCode: null,
      parcelCode: null,
      purchaseOrder: null,
      transport: 'car',
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      ID: this.$route.params.id,
      items: [],
      address: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    isDisabled() {
      return (this.items.come_chinesewarehouse || this.items.out_chinesewarehouse || this.items.come_thaiwarehouse
      )
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(pathfile, picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      picture.forEach(picture => {
        const img = { path: this.GetImg(pathfile, picture) }
        this.images.push(img)
      })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    checkInput() {
      if (/\s/.test(this.items.postnumber) || /\t/.test(this.items.postnumber)) {
        this.items.postnumber = this.items.postnumber.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        this.$http.get('/Tracking/getPostone', { params })
          .then(response => {
            this.items = response.data
            console.log(this.items)
          })
      } catch (e) {
        console.log(e)
      }
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return this.$t('calculateByKilo')
      } if (item.charge_per_save === item.charge_per_cue) {
        return this.$t('key-98')
      }
      return ''
    },
    CommasCue(x) {
      if (!x) {
        return '0'
      }
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (parts[1]) {
        parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
      } else {
        parts.push('0000')
      }
      return parts.join('.')
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.items.postnumber) {
            return this.SwalError(this.$t('key-309'))
          }
          if (!this.items.product_amount) {
            return this.SwalError(this.$t('key-309'))
          }
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            _id: this.items._id,
            postnumber: this.items.postnumber,
            product_amount: this.items.product_amount,
            tag_id: this.items.tag_id,
            keyword: this.items.keyword,
            transportType: this.items.transportType,
            packbox: this.items.packbox,
            checkproduct: this.items.checkproduct,
          }
          // console.log(formData)
          this.$http
            .post('/Tracking/UpdatePostnumber', obj)
            .then(response => {
              if (response.data.success) {
                this.Success(this.$t('key-286'))
                this.$router.push({ name: 'tracking' })
              } else {
                this.SwalError(response.data.data)
              }
            })
            .catch(error => {
              this.SwalError(error.response.data.data)
            })
        }
      })
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    Checkname() {
      if (this.$i18n.locale === 'th') {
        return 'pro_name'
      } if (this.$i18n.locale === 'en') {
        return 'pro_name_en'
      } if (this.$i18n.locale === 'cn') {
        return 'pro_name_cn'
      }
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
